import React from "react";
import "./ResourcesSection.css";


export const ResourcesSection = () => {
  return (
    <div className="resources-section">
      <p>Most high schools today operate under a traditional model of education that was established well over a hundred years ago – a model that measures learning progress by standardized benchmarks, such as time spent in seats and test scores.</p>
      {/* <MoreResources config={config.resources} /> */}
    </div>
  );
};
