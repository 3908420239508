import React from "react";
import { MoreResources } from "../../common/MoreResources";
import "./ResourcesSection.css";
import { ResultPageConfig } from "../../lib/types/cmsConfigs";

type ResourcesSectionProps = {
  config: ResultPageConfig;
};

export const ResourcesSection = ({ config }: ResourcesSectionProps) => {
  return (
    <div className="results-resources-section">
      <MoreResources config={config.resources} />
    </div>
  );
};
