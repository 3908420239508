import React from "react";
import "./ResourcesList.css";

export const ResourcesList = () => {
  return (
    <div className="resources-section">
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}>Basics</th>
          </tr>
          <tr>
            <td>What Is a School Board?</td>
            <td>Live and recorded sessions with experts in competency-based education</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>Where to Find School Data</td>
            <td>Use this handy list of informative links.</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>Know Your School Board</td>
            <td>Who’s who at a typical school board meeting.</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>What Happens at a School Board Meeting?</td>
            <td>There are 14,000 school boards in the US. Most follow the same basic rules.</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>Elected or Appointed?</td>
            <td>Some boards are elected, some appointed. Which is yours?</td>
            <td><a href="/">View</a></td>
          </tr>
        </table>
      </div>
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}>Rethinking High School</th>
          </tr>
          <tr>
            <td>High Schools and School Boards</td>
            <td>Can school boards help transform high school? Yes, they can.</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>Students and School Boards</td>
            <td>How can high school students make an impact?</td>
            <td><a href="/">View</a></td>
          </tr>
        </table>
      </div>
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}>Get Involved</th>
          </tr>
          <tr>
            <td>How to Write a Letter to Your School Board</td>
            <td>Voice a concern, make a suggestion, or simply ask a question.</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>Be a changemaker</td>
            <td>Here&apos;s how to do it.</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>Five Questions to Ask Your School Board</td>
            <td>It’s time to get curious about your school system.</td>
            <td><a href="/">View</a></td>
          </tr>
          <tr>
            <td>Why Equity is Worth Fighting For</td>
            <td>Schools can&apos;t all be the same, but they can be equitable</td>
            <td><a href="/">View</a></td>
          </tr>
        </table>
      </div>
    </div>
  );
};
