import React, { useEffect } from "react";
// import { AddressLookupSection } from "./sections/AddressLookupSection";
import { useAboutPageEndpoint } from "../hooks/wordpressCms";
import { Backdrop, CircularProgress } from "@mui/material";
import { Footer } from "../common/Footer";
import { ResourcesSection } from "./sections/ResourcesSection";
import { ResourcesList } from "./sections/ResourcesList";
import "./About.css";

function About() {
  const { loadAboutPage, aboutPageConfig } = useAboutPageEndpoint();
  useEffect(() => {
    loadAboutPage();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal }}
        open={!aboutPageConfig}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {aboutPageConfig && (
        <>
          <div className="resources-section resources-heading">
            <h1>Resources</h1>
          </div>
          <ResourcesSection />
          <ResourcesList />
          <Footer config={aboutPageConfig.footer} />
        </>
      )}
    </div>
  );
}

export default About;
